import { createTheme as createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { generateThemeUpdatePayload } from 'account/components/theme/helpers'
import { ThemeReducer } from 'app/plugins/account/components/theme/model'
import { themeActions } from 'core/session/themeReducers'
import useScopedPreferences from 'core/session/useScopedPreferences'
import AppTheme from 'core/themes/model'
import customSerenityTheme from 'core/themes/serenity/serenityCustom'
import defaultTheme from 'core/themes/serenity/serenityDefault'
import * as CSS from 'csstype'
import { prop } from 'ramda'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { snakeToCamelString } from 'utils/misc'
import { themeSelector } from './selectors'

const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)')
const prefersLightScheme = window.matchMedia('(prefers-color-scheme: light)')

// const defaultThemeToUse = prefersLightScheme.matches
//   ? lightTheme
//   : prefersDarkScheme.matches
//   ? darkTheme
//   : defaultTheme

// For OpenCloud for now
const defaultThemeToUse = defaultTheme

const CustomThemeContext = React.createContext<{
  theme: AppTheme
  setCustomTheme: (theme: AppTheme) => void
}>({
  theme: null,
  setCustomTheme: (theme) => {
    throw new Error('CustomThemeProvider not found')
  },
})

export const CustomThemeConsumer = CustomThemeContext.Consumer
export const CustomThemeProvider = CustomThemeContext.Provider

export const loadingStyles: CSS.Properties = {
  width: '100%',
  fontSize: '20px',
  textAlign: 'center',
  marginTop: '4rem',
}

const ThemeManager = ({ children }) => {
  const dispatch = useDispatch()
  const themeStore = useSelector(prop<string, ThemeReducer>('theme'))
  const { global: globalTheme } = themeStore
  const { prefs, updatePrefs } = useScopedPreferences()
  const { theme: themeName = 'light' } = prefs
  useEffect(() => {
    dispatch(themeActions.updateTheme(defaultThemeToUse))
  }, [])
  const jsonTheme = useSelector(themeSelector)
  const setCustomTheme = useCallback<(theme: AppTheme) => void>(
    (customTheme: AppTheme, updateUserPrefs = true) => {
      if (updateUserPrefs) {
        // @ts-ignore
        updatePrefs({ theme: customTheme?.theme?.palette?.themeKey })
      }
      dispatch(themeActions.updateTheme(customTheme))
    },
    [jsonTheme],
  )

  useEffect(() => {
    const loadTheme = async () => {
      try {
        // If any global settings are set, default to custom theme, then apply global settings
        if (globalTheme?.headerColor || globalTheme?.sidenavColor || globalTheme?.logoUrl) {
          updatePrefs({ theme: customSerenityTheme?.theme?.palette?.themeKey })
          dispatch(themeActions.updateTheme(customSerenityTheme))
          dispatch(themeActions.updateThemeComponent(generateThemeUpdatePayload(globalTheme)))
          return
        }
        const fileName = snakeToCamelString(`serenity-${String(themeName)}`)
        if (themeName === 'default') {
          return
        }
        const loadedTheme = await import(`core/themes/serenity/${fileName}.ts`)

        if (!loadedTheme) {
          console.error(`Unable to load ${themeName}.ts`)
          return
        }
        const themeComponentPayload = generateThemeUpdatePayload({
          headerColor: loadedTheme?.default?.components?.header?.background,
          sidenavColor: loadedTheme?.default?.components?.sidebar?.background,
        })
        dispatch(themeActions.updateTheme(loadedTheme?.default))
        dispatch(themeActions.updateThemeComponent(themeComponentPayload))
      } catch (err) {
        console.error(err)
      }
    }
    loadTheme()
  }, [themeName, globalTheme])

  // Rendering the app before the theme is loaded will have issues because `withStyles`
  // requires the `theme` object to exist.
  if (!jsonTheme) {
    return <h2 style={loadingStyles}>Loading theme...</h2>
  }
  // TODO: Our current theme (AppTheme) is not extending the MUI theme correctly
  // Until we fix it we have to trick the TS engine to swallow this
  const theme = useMemo(() => createMuiTheme(jsonTheme as unknown), [jsonTheme]) as AppTheme

  // @ts-ignore
  window.theme = theme

  return (
    <ThemeProvider theme={theme as AppTheme}>
      <CustomThemeProvider value={{ theme, setCustomTheme }}>{children}</CustomThemeProvider>
    </ThemeProvider>
  )
}

export function useCustomTheme(): [AppTheme, (theme: AppTheme, updateUserPrefs?: boolean) => void] {
  const { theme, setCustomTheme } = useContext(CustomThemeContext)
  return [theme, setCustomTheme]
}

export const withCustomTheme = (Component) => (props) => (
  <CustomThemeConsumer>
    {({ theme, setCustomTheme }) => (
      <Component {...props} theme={theme} setCustomTheme={setCustomTheme} />
    )}
  </CustomThemeConsumer>
)

export default ThemeManager
