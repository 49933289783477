import { AppPlugins, pluginRoutePrefix } from 'app/constants'
import { Route } from 'core/plugins/route'

export const accountRoutes = {
  accountStatus: {
    root: Route.register({
      url: `${pluginRoutePrefix}/status`,
      defaultParams: {
        plugin: AppPlugins.MyAccount,
      },
      name: 'AccountStatus:Root',
    }),
  },
  userSettings: {
    root: Route.register({
      url: `${pluginRoutePrefix}/settings/:tab`,
      defaultParams: {
        plugin: AppPlugins.MyAccount,
        tab: 'profile',
      },
      name: 'UserSettings:Root',
    }),
  },
  sso: {
    root: Route.register({
      url: `${pluginRoutePrefix}/sso/:tab`,
      defaultParams: {
        plugin: AppPlugins.MyAccount,
        tab: 'configure',
      },
      name: 'SsoManagement:Root',
    }),
    addGroup: Route.register({
      url: `${pluginRoutePrefix}/sso/groups/add`,
      defaultParams: {
        plugin: AppPlugins.MyAccount,
      },
      name: 'SsoManagement:Group:Add',
    }),
    editGroup: Route.register({
      url: `${pluginRoutePrefix}/sso/groups/edit/:id`,
      defaultParams: {
        plugin: AppPlugins.MyAccount,
      },
      name: 'SsoManagement:Group:Edit',
    }),
  },
  customTheme: Route.register({
    url: `${pluginRoutePrefix}/theme`,
    defaultParams: {
      plugin: AppPlugins.MyAccount,
    },
    name: 'CustomTheme',
  }),
}
