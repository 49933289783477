import { IDataKeys } from 'k8s/datakeys.model'
import { either, equals, isNil, pickAll, pipe, reject } from 'ramda'
import Action, { ArrayElement } from 'core/actions/Action'
import { cacheActions } from 'core/caching/cacheReducers'
import store from 'app/store'
import { allKey } from 'app/constants'
import { emptyArr, ensureArray } from 'utils/fp'

const { dispatch } = store

// This convertResource function is intended to work with kubernetes resources
// If used with eg. clusters or cloudProviders, this would maybe work fine still
// @fixme this shouldn't be here, it is too business related
const convertResource = (item, clusterId) => ({
  clusterId,
  name: item?.metadata?.name,
  created: item?.metadata?.creationTimestamp,
  id: item?.metadata?.uid,
  namespace: item?.metadata?.namespace,
  ...item,
})

class CreateAction<
  D extends keyof IDataKeys,
  P extends Record<string, unknown> = Record<string, unknown>,
  R = ArrayElement<IDataKeys[D]>
> extends Action<D, P, R> {
  public get name() {
    return 'create'
  }

  protected async preProcess(params: P): Promise<void> {
    const { cacheKey, addCacheKey } = this.config
    dispatch(cacheActions.setUpdating({ cacheKey, updating: true }))
    if (addCacheKey) {
      dispatch(cacheActions.setUpdating({ cacheKey: addCacheKey, updating: true }))
    }
  }

  protected postProcess = (result: R, params: P) => {
    if (!result) return
    const { indexBy = emptyArr, cacheKey, addCacheKey } = this.config
    const allIndexKeys = indexBy ? ensureArray(indexBy) : emptyArr
    const indexedParams = pipe(pickAll(allIndexKeys), reject(either(isNil, equals(allKey))))(params)

    dispatch(
      cacheActions.addItem({
        cacheKey,
        params: indexedParams,
        item: convertResource(result, params.clusterId),
      }),
    )
    dispatch(cacheActions.setUpdating({ cacheKey, updating: false }))
    if (addCacheKey) {
      dispatch(
        cacheActions.addItem({
          cacheKey: addCacheKey,
          params: indexedParams,
          item: convertResource(result, params.clusterId),
        }),
      )
      dispatch(cacheActions.setUpdating({ cacheKey: addCacheKey, updating: false }))
    }
  }

  protected handleError(err, params: P, options) {
    const { cacheKey, addCacheKey } = this.config
    // Without this, if an error occurs loading can be stuck as true
    dispatch(cacheActions.setUpdating({ cacheKey, updating: false }))
    if (addCacheKey) {
      dispatch(cacheActions.setUpdating({ cacheKey: addCacheKey, updating: false }))
    }
    return super.handleError(err, params, options)
  }
}

export default CreateAction
