import React, { useEffect, useCallback, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { path } from 'ramda'
import Input from 'core/elements/input/Input'
import { debounce } from 'utils/async'
import clsx from 'clsx'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Dropdown from 'core/elements/dropdown'
import Text from 'core/elements/Text'
import { range } from 'ramda'

interface Props {
  renderSelectableCardComponent?: (item: any) => JSX.Element
  data: any[]
  searchProps?: string[]
  maxRows?: number
  numColumns?: number
}

const useStyles = makeStyles<Theme, Partial<Props>>((theme) => ({
  // navigation: {
  //   color: theme.components.typography.passive,
  //   fontSize: 14,
  //   display: 'flex',
  //   gridAutoFlow: 'column',
  //   justifyContent: 'space-between',
  //   padding: '8px 8px 8px 16px',
  //   minHeight: 36,
  //   gap: 16,
  //   left: 0,
  // },
  container: {
    display: 'grid',
    gap: 16,
  },
  cards: {
    display: 'inline-grid',
    gridTemplateColumns: ({ numColumns }) => `repeat(${numColumns}, 1fr)`,
    gap: 16,
  },
  controls: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },
  dropdown: {
    display: 'inline-flex',
    flexFlow: 'row nowrap',
    justifyContent: 'right',
    alignItems: 'center',
    '& .inputFrame': {
      borderWidth: 1,
      marginRight: theme.spacing(1),
    },
    '& .placeholder': {
      fontWeight: 400,
    },
    '& .dropdownMenu': {
      minWidth: 50,
    },
  },
  nav: {
    textAlign: 'center',
    margin: theme.spacing(0, 2),
    fontWeight: 400,
  },
  navBtn: {
    width: 24,
    height: 24,
    borderRadius: 4,
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 150ms ease',
    '&:hover': {
      backgroundColor: theme.components.table.hoverBackground,
    },
  },
  withBackground: {
    display: 'grid',
    gap: 16,
    // background: theme.palette.grey[50],
    margin: '0px -26px 0px -57px',
    padding: '32px 26px 32px 57px',
  },
  centered: {
    textAlign: 'center',
  },
}))

const getPagesCount = (totalItems, pageSize) => {
  const tmpPageCount = Math.ceil(totalItems / pageSize) - 1
  return (tmpPageCount > 0 ? tmpPageCount : 0) + 1
}

export default function PaginatedSelectableCards({
  renderSelectableCardComponent,
  data = [],
  searchProps = [],
  maxRows = 2,
  numColumns = 3,
}: Props) {
  const classes = useStyles({ numColumns })
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState('')
  const [page, setPage] = useState<number>(1)

  const pageSize = maxRows * numColumns

  const debouncedUpdateFilterValue = useMemo(() => debounce<[string], void>(setDebouncedSearch), [])
  const handleOnChange = useCallback(async ({ target: { value } }) => {
    setSearch(value)
    return debouncedUpdateFilterValue(value)
  }, [])

  useEffect(() => {
    return () => {
      debouncedUpdateFilterValue.cancel()
    }
  }, [])

  const filteredData = useMemo(() => {
    if (!debouncedSearch.length || !searchProps.length) {
      return data
    }
    return data.filter((item) => {
      return !!searchProps.find((prop) =>
        String(item.hasOwnProperty(prop) ? item[String(prop)] : path(String(prop).split('.'), item))
          .toLocaleLowerCase()
          .includes(debouncedSearch.toLocaleLowerCase()),
      )
    })
  }, [debouncedSearch, data, searchProps])

  // Pagination
  const pagesCount = useMemo<number>(() => {
    return getPagesCount(filteredData.length, pageSize)
  }, [filteredData, pageSize])

  const pageOptions = useMemo(() => range(1, pagesCount + 1).map((value) => ({ value })), [
    pagesCount,
  ])

  const currentPage = useMemo<number>(() => {
    return page > pagesCount ? pagesCount : page
  }, [page, pagesCount])

  const goToPage = useCallback(
    async (page: number) => {
      const currentPage = page > pagesCount ? pagesCount : page < 1 ? 1 : page
      setPage(currentPage)
    },
    [pagesCount, pageSize],
  )

  const goPrevPage = useCallback(() => {
    const prevPage = currentPage > 1 ? currentPage - 1 : 1
    setPage(prevPage)
  }, [currentPage, pageSize])

  const goNextPage = useCallback(() => {
    const nextPage = currentPage < pagesCount ? currentPage + 1 : currentPage
    setPage(nextPage)
  }, [currentPage, pagesCount, pageSize])

  const paginatedData = useMemo(() => {
    const startIdx = (currentPage - 1) * pageSize
    const endIdx = startIdx + pageSize
    return filteredData.slice(startIdx, endIdx)
  }, [filteredData, pageSize, currentPage])

  return (
    <div className={classes.container}>
      <Input
        icon="search"
        placeholder="Search"
        className={classes.searchBar}
        value={search !== undefined ? search : ''}
        onChange={handleOnChange}
        type="search"
      />
      <div className={classes.withBackground}>
        {renderSelectableCardComponent && (
          <div className={classes.cards}>
            {paginatedData.map((item) => renderSelectableCardComponent(item))}
          </div>
        )}
        {!paginatedData?.length && (
          <div className={classes.centered}>
            <Text variant="subtitle2">No Images of this type available</Text>
          </div>
        )}
        <div className={clsx(classes.controls, 'paginationControls')}>
          <FontAwesomeIcon
            disabled={currentPage === 1}
            aria-hidden="true"
            className={classes.navBtn}
            onClick={goPrevPage}
          >
            angle-left
          </FontAwesomeIcon>
          <Text component="div" variant="inputLabel" className={classes.nav}>
            <Dropdown
              className={classes.dropdown}
              label="Page"
              compact
              items={pageOptions}
              value={currentPage}
              onChange={goToPage}
            />
            of {pagesCount}
          </Text>

          <FontAwesomeIcon
            disabled={currentPage === pagesCount}
            aria-hidden="true"
            className={classes.navBtn}
            onClick={goNextPage}
          >
            angle-right
          </FontAwesomeIcon>
        </div>
      </div>
    </div>
  )
}
