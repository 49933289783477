import { UseParamsReturnType } from 'core/hooks/useParams'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { useMemo } from 'react'
import { generateObjMemoizer } from 'utils/misc'

export type GlobalParams = {
  clusterId: string[]
  namespace: string[]
  tenant: string[]
}

export default function useGlobalParams<P>(
  useParamsHook: (defaultParams?: P) => UseParamsReturnType<P>,
  defaultParams: P,
): UseParamsReturnType<P> & {
  allParams: P & GlobalParams
  globalParams: GlobalParams
  updateGlobalParams: (globalParams: Partial<GlobalParams>) => void
} {
  const memoizedParams = useMemo(() => generateObjMemoizer<P & GlobalParams>(), [])
  const prefParams = useParamsHook(defaultParams)
  const { params } = prefParams
  const { prefs: globalParams, updatePrefs: updateGlobalParams } = <
    { prefs: GlobalParams; updatePrefs: (prefs: Partial<GlobalParams>) => void }
  >useScopedPreferences<GlobalParams>('globalParams')
  const allParams = memoizedParams({ ...params, ...globalParams })
  return { ...prefParams, allParams, globalParams, updateGlobalParams }
}
