import { memoize, pathJoin } from 'app/utils/misc'
import { devEnabled } from 'core/utils/helpers'
import { apply, Dictionary, toPairs } from 'ramda'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { ensureArray, isNilOrEmpty } from 'utils/fp'

import { renderDevTools, renderPublicPages } from './base-views'
import { PluginSection } from './model'
import Plugin from './plugin'

export const parseNavItem = (basePath) => (navItem) => ({
  ...navItem,
  link: {
    ...navItem.link,
    path: navItem.link.external ? navItem.link.path : pathJoin(basePath, navItem.link.path),
    navPath: navItem.link.navPath ? pathJoin(basePath, navItem.link.navPath) : null,
    definition: navItem.link.definition ? pathJoin(basePath, navItem.link.definition) : null,
    defaultPath: navItem.link.defaultPath ? pathJoin(basePath, navItem.link.defaultPath) : null,
  },
  nestedLinks: navItem.nestedLinks ? navItem.nestedLinks.map(parseNavItem(basePath)) : null,
})

const defaultOptions = {
  showFooter: false,
  showNavMenu: true,
  showSidebar: false,
}

export const initData = (frame = null) => ({
  frame,
  components: [],
  routes: [],
  navItems: [],
  secondaryHeader: null,
  options: { ...defaultOptions },
})

export const renderPluginRoutes = (role) => (id, plugin) => {
  const defaultRoute = plugin.getDefaultRoute()
  const genericRoutes = [
    {
      link: { path: pathJoin(plugin.basePath, '') },
      // TODO: Implement 404 page
      render: () => <Redirect to={defaultRoute || '/ui/404'} />,
    },
  ]
  const filteredRoutes = plugin
    .getRoutes()
    .filter(
      ({ requiredRoles }) =>
        isNilOrEmpty(requiredRoles) || ensureArray(requiredRoles).includes(role),
    )

  return [...filteredRoutes, ...genericRoutes].map((route) => {
    const { component: Component, render, link } = route
    return (
      <Route
        key={link.path}
        path={link.path}
        exact={link.exact || false}
        render={render}
        component={Component}
      />
    )
  })
}

export const renderPlugins = memoize((plugins, role) =>
  toPairs(plugins)
    .map(apply(renderPluginRoutes(role)))
    .flat(),
)

export const renderPluginComponents = (id, plugin) => {
  const pluginComponents = plugin.getComponents()

  return (
    <Route key={plugin.basePath} path={plugin.basePath} exact={false}>
      {pluginComponents.map((PluginComponent, idx) => (
        <PluginComponent key={idx} />
      ))}
    </Route>
  )
}

export const renderRawComponents = memoize((plugins) =>
  toPairs(plugins)
    .map(apply(renderPluginComponents))
    .flat(),
)

export const getSections = memoize((plugins: Dictionary<Plugin>, role, features): PluginSection[] =>
  toPairs(plugins).map(([id, plugin]) => ({
    id,
    name: plugin.name,
    icon: plugin.icon,
    description: plugin.description,
    links: plugin
      .getNavItems()
      .filter(
        ({ requiredRoles }) =>
          isNilOrEmpty(requiredRoles) || ensureArray(requiredRoles).includes(role),
      )
      .filter(
        ({ requiredFeatures }) => isNilOrEmpty(requiredFeatures) || requiredFeatures(features),
      ),
  })),
)

export const renderMainContent = memoize((plugins, role, isDevEnabled = devEnabled()) => (
  <>
    {renderRawComponents(plugins)}
    <Switch>
      {renderPlugins(plugins, role)}
      {renderPublicPages()}
    </Switch>
    {isDevEnabled && renderDevTools()}
  </>
))
