import React, {
  ReactNode,
  FC,
  PropsWithChildren,
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react'
import { makeStyles, ThemeProvider, styled } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { GridFilteringProps } from './hooks/useGridFiltering'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Text from 'core/elements/Text'
import { GridBatchActionGroupProps, GridBatchActionsProps } from './hooks/useGridSelectableRows'
import darkTheme from 'core/themes/serenity/serenityDark'
import generateTestId from 'utils/test-helpers'
import { useCustomTheme } from 'core/themes/ThemeManager'
import GridColumnsPopover from './GridColumnsPopover'
import { GridManagedColumnsProps } from './hooks/useGridManagedColumns'
import Tooltip from 'core/elements/tooltip'
import useToggler from 'core/hooks/useToggler'
import { equals } from 'ramda'
import { ensureArray } from 'utils/fp'

interface GridToolbarProps<
  T,
  GF extends Record<string, unknown>,
  F extends Record<string, unknown>,
  DF extends Record<string, unknown>
> extends GridFilteringProps<GF, F, DF>, GridBatchActionsProps<T>, GridManagedColumnsProps {
  compact?: boolean
  label?: string
  onRefresh?: () => void | Promise<void>
  extraToolbarContent?: ReactNode
  ToolbarContainer?: FC<PropsWithChildren<{ className?: string; selectedCount?: number }>>
  showItemsCountInLabel?: boolean
  itemsCount?: number
  tooltip?: ReactNode
  isReadOnly?: boolean
}

const useStyles = makeStyles<
  Theme,
  { compact?: boolean; selectedCount?: number; filtersOpen?: boolean }
>((theme) => ({
  gridToolbar: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.components.table.activeToolbarColor,
    backgroundColor: () => theme.components.table.activeToolbar,
    transitionTimingFunction: 'ease-in',
    transition: 'background-color .2s ease',
    border: 0,
    borderRadius: '4px 4px 0 0',
    padding: '8px 16px 8px 24px',
    minHeight: 56,
    borderBottom: `1px solid ${theme.components.table.border}`,
    boxSizing: 'border-box',
    '&:last-child td': {
      borderBottom: 0,
    },
  },
  label: {
    ...theme.typography.subtitle2,
    color: 'inherit',
    marginRight: theme.spacing(3),
  },
  selectedCount: {
    color: 'inherit',
  },
  clearBtn: {
    cursor: 'pointer',
    color: theme.components.table.toolbarPassiveColor,
  },
  verticalLine: {
    borderLeft: `1px solid ${theme.components.typography.default}`,
    width: 1,
    height: 34,
    margin: theme.spacing(0, 1),
  },
  batchActions: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    gap: 12,
  },
  tools: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    justifyContent: 'end',
    gap: 16,
  },
  buttons: {
    display: 'grid',
    gridAutoFlow: 'column',
    marginRight: theme.spacing(1),
  },
  button: {
    ...theme.typography.inputTable,
    display: 'grid',
    gridAutoFlow: 'column',
    cursor: 'pointer',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    borderRadius: 4,
    gap: 8,
    '&:hover': {
      backgroundColor: theme.components.table.hoverBackground,
    },
  },
  extraContent: {
    marginLeft: theme.spacing(1),
  },
  tooltip: {
    display: 'inline-block',
    marginLeft: 8,
  },
  actionDropdown: {
    position: 'relative',
  },
  dropdownLabel: {
    display: 'inline-grid',
    gap: 8,
    gridTemplateColumns: 'repeat(3, max-content)',
    alignItems: 'center',
    cursor: 'pointer',
  },
  dropdownMenu: {
    display: 'grid',
    background: theme.components.dropdown.selectedColor, // Bc toolbar uses opposite mode, use the opposite colors
    position: 'absolute',
    zIndex: 100,
    top: 32,
    borderRadius: 4,
    border: `solid 1px ${theme.components.tooltip.border}`,
  },
  filterBar: {
    transition: 'height .2s ease',
    height: ({ filtersOpen }) => (filtersOpen ? 'max-content' : 0),
    overflow: ({ filtersOpen }) => (filtersOpen ? 'visible' : 'hidden'),
    padding: ({ filtersOpen }) => (filtersOpen ? '16px' : '0px'),
    background: theme.components.table.hoverBackground,
    display: 'grid',
    gap: 16,
  },
  filterDropdowns: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
  },
  activeFilters: {
    display: 'flex',
    gap: 16,
  },
  activeFilterBox: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, max-content)',
    gap: 8,
    alignItems: 'center',
    padding: '4px 12px',
    background: theme.components.table.background,
    border: `1px solid ${theme.components.table.border}`,
  },
  activeFilterText: {
    fontSize: 12,
  },
  withIcon: {
    display: 'grid',
    gridTemplateColumns: '16px auto',
    gap: 12,
    alignItems: 'center',
  },
}))

const DefaultToolbarContainer: FC<PropsWithChildren<{
  className?: string
}>> = ({ children, ...props }) => {
  const [theme] = useCustomTheme()
  return (
    <ThemeProvider theme={darkTheme}>
      <div {...props}>{children}</div>
    </ThemeProvider>
  )
  // return (
  //   <ThemeProvider theme={selectedCount ? darkTheme : theme}>
  //     <div {...props}>{children}</div>
  //   </ThemeProvider>
  // )
}

// Consider moving to a helper file
// Detecting clicks outside the dropdown
const clickOutside = (ref, onClickOutside) => {
  useEffect(() => {
    const modalRef = document.getElementById('modal-portal-root')
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target) && !modalRef.contains(event.target)) {
        onClickOutside()
      }
    }
    // Bind
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // dispose
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, onClickOutside])
}

function BatchActionDropdown<T>({
  actions,
  icon,
  label = 'Other Actions',
}: GridBatchActionGroupProps<T>) {
  // TODO: Add a click handler for outside of the element
  const classes = useStyles({})
  const [isOpen, setOpen] = useState(false)

  const wrapperRef = useRef(null)
  clickOutside(wrapperRef, () => {
    setOpen(false)
  })

  return (
    <div className={classes.actionDropdown} ref={wrapperRef}>
      <div className={classes.dropdownLabel} onClick={() => setOpen(!isOpen)}>
        <FontAwesomeIcon>{icon}</FontAwesomeIcon>
        <Text variant="body2">{label}</Text>
        <FontAwesomeIcon solid size="sm">
          {isOpen ? 'caret-up' : 'caret-down'}
        </FontAwesomeIcon>
      </div>
      {isOpen && (
        <div className={classes.dropdownMenu}>
          {actions?.map(({ key, label, triggerAction, BatchActionButton, icon, ...props }) => (
            <BatchActionButton
              {...props}
              key={key}
              onClick={triggerAction}
              closeDropdown={() => setOpen(false)}
            >
              <div className={classes.withIcon}>
                {icon && <FontAwesomeIcon noBaseClass>{icon}</FontAwesomeIcon>}
                {label}
              </div>
            </BatchActionButton>
          ))}
        </div>
      )}
    </div>
  )
}

const GridRefreshButton = styled(({ className, onRefresh }) => {
  return (
    <Text
      data-testid={generateTestId('refresh')}
      noWrap
      onClick={onRefresh}
      component="div"
      className={className}
    >
      <FontAwesomeIcon>sync-alt</FontAwesomeIcon>
      Refresh
    </Text>
  )
})<Theme>(({ theme }) => ({
  ...theme.typography.inputTable,
  display: 'grid',
  gridAutoFlow: 'column',
  cursor: 'pointer',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  borderRadius: 4,
  gap: 8,
  '&:hover': {
    backgroundColor: theme.components.table.hoverBackground,
  },
}))

export default function GridToolbar<
  T,
  GF extends Record<string, unknown>,
  F extends Record<string, unknown>,
  DF extends Record<string, unknown>
>(props: GridToolbarProps<T, GF, F, DF>) {
  const [isOpen, toggleIsOpen] = useToggler(false)
  const classes = useStyles({ ...props, filtersOpen: isOpen })
  const {
    label,
    columns,
    columnTogglers,
    selectedCount,
    batchActions,
    dropdownBatchActions,
    globalFilters,
    filters,
    dropdownFilters,
    dropdownFilterValues,
    dropdownValuesByKey,
    onRefresh,
    extraToolbarContent,
    clearSelectedRows,
    multiSelectionEnabled,
    ToolbarContainer = DefaultToolbarContainer,
    columnHidingDisabled,
    showItemsCountInLabel = false,
    itemsCount = undefined,
    tooltip = undefined,
    isReadOnly,
  } = props
  const removeFilterValue = useCallback(
    (filterValue) => {
      const keyValues = ensureArray(dropdownValuesByKey[filterValue.key])
      const filteredKeyValues = keyValues.filter((val) => {
        return !equals(val, filterValue.value)
      })
      filterValue.updateFilterValue(filteredKeyValues)
    },
    [dropdownValuesByKey],
  )
  return (
    <>
      <ToolbarContainer className={classes.gridToolbar}>
        <div className={classes.batchActions}>
          <Text
            data-testid={generateTestId(label, 'label')}
            className={classes.label}
            variant="subtitle2"
            component="p"
          >
            {showItemsCountInLabel && itemsCount ? `${label} (${itemsCount})` : label}
            {tooltip && (
              <Tooltip className={classes.tooltip} message={tooltip}>
                <FontAwesomeIcon>question-circle</FontAwesomeIcon>
              </Tooltip>
            )}
          </Text>
          <>
            {multiSelectionEnabled ? (
              <>
                <Text
                  data-testid={generateTestId('selected')}
                  variant="body2"
                  className={classes.selectedCount}
                  component="p"
                >
                  {`${selectedCount} Selected`}
                </Text>
                <Text
                  data-testid={generateTestId('clear', 'all')}
                  variant="body2"
                  className={classes.clearBtn}
                  component="p"
                  onClick={clearSelectedRows}
                >
                  {`Clear All`}
                </Text>
                <div className={classes.verticalLine} />
              </>
            ) : null}
            {!isReadOnly &&
              batchActions?.map(({ key, label, triggerAction, BatchActionButton, ...props }) => (
                <BatchActionButton {...props} key={key} onClick={triggerAction}>
                  {label}
                </BatchActionButton>
              ))}
            {!!dropdownBatchActions?.length && !isReadOnly && (
              <>
                {dropdownBatchActions.map(({ icon, label, actions }) => (
                  <BatchActionDropdown<T> key={label} icon={icon} label={label} actions={actions} />
                ))}
              </>
            )}
          </>
        </div>
        <div data-testid={generateTestId('search')} className={classes.tools}>
          {globalFilters.map(
            ({ key, filterValue, filterValues, updateFilterValue, FilterComponent, ...rest }) => (
              <FilterComponent
                key={String(key)}
                value={filterValue}
                filterValues={filterValues}
                onChange={updateFilterValue}
                {...rest}
              />
            ),
          )}

          <div className={classes.buttons}>
            {!columnHidingDisabled && (
              <GridColumnsPopover columns={columns} columnTogglers={columnTogglers} />
            )}
            {onRefresh && <GridRefreshButton onRefresh={onRefresh} />}
            {!!dropdownFilters?.length && (
              <Text noWrap component="div" className={classes.button} onClick={toggleIsOpen}>
                <FontAwesomeIcon>filter</FontAwesomeIcon>
                Filters
                <FontAwesomeIcon>{isOpen ? 'angle-up' : 'angle-down'}</FontAwesomeIcon>
              </Text>
            )}
          </div>

          {filters.map(
            ({ key, filterValue, filterValues, updateFilterValue, FilterComponent, ...rest }) => (
              <FilterComponent
                key={String(key)}
                value={filterValue}
                filterValues={filterValues}
                onChange={updateFilterValue}
                {...rest}
              />
            ),
          )}
          {extraToolbarContent ? (
            <div className={classes.extraContent}>{extraToolbarContent}</div>
          ) : null}
        </div>
      </ToolbarContainer>
      <div className={classes.filterBar}>
        <div className={classes.filterDropdowns}>
          <Text variant="caption1">Filters:</Text>
          {dropdownFilters?.map(
            ({ key, filterValue, updateFilterValue, FilterComponent, filterComponentProps }) => (
              <FilterComponent
                key={key}
                value={filterValue}
                onChange={updateFilterValue}
                {...filterComponentProps}
              />
            ),
          )}
        </div>
        {!!dropdownFilterValues?.length && (
          <div className={classes.activeFilters}>
            {dropdownFilterValues?.map((filterInfo) => (
              <div key={filterInfo?.display} className={classes.activeFilterBox}>
                <Text variant="body2" className={classes.activeFilterText}>
                  <b>{filterInfo?.label}:</b> {filterInfo?.display}
                </Text>
                <FontAwesomeIcon
                  onClick={() => {
                    removeFilterValue(filterInfo)
                  }}
                  size="md"
                >
                  xmark
                </FontAwesomeIcon>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}
