import { AppPlugins, CustomerTiers, ssoRedirectUrl } from 'app/constants'
import { pathStrOr } from 'utils/fp'
import { sessionStoreKey } from 'core/session/sessionReducers'
import store from 'app/store'

export const isKaapiEnabled = () => {
  const state = store.getState()
  const { features } = state[sessionStoreKey]
  return pathStrOr(false, 'enable_kaapi', features)
}

export const isDecco = (features) => pathStrOr(false, 'experimental.kplane', features)

export const isDeccoEnv = () => {
  const state = store.getState()
  const { features } = state[sessionStoreKey]
  return isDecco(features)
}

export const isProductionEnv = process.env.NODE_ENV === 'production'

export const showZendeskChatOption = (lastStack, customerTier) =>
  (lastStack === AppPlugins.Kubernetes || lastStack === AppPlugins.MyAccount) &&
  customerTier === CustomerTiers.Enterprise &&
  isProductionEnv

export const convertListOfObjectsToMap = (key: string, objects: any[]) => {
  const result = new Map()
  for (const obj of objects) {
    if (obj[key]) {
      result.set(obj[key], obj)
    }
  }
  return result
}

export const devEnabled = () => window.localStorage.enableDevPlugin === 'true'

const READONLY_ROLES = ['reader']
export const isReadonlyUser = () => {
  const state = store.getState()
  const { userDetails } = state[sessionStoreKey]
  return READONLY_ROLES.includes(userDetails?.role)
}

export const isDefaultDomain = (domain?: string) => {
  return (
    !domain ||
    domain.trim() === '' ||
    domain.trim() === 'Default Domain' ||
    domain?.trim()?.toLowerCase() === 'default'
  )
}

export const getSSORedirectUrl = (domain?: string) => {
  if (isDefaultDomain(domain)) {
    return ssoRedirectUrl
  } else {
    return ssoRedirectUrl.replace('IDP1', domain)
  }
}
