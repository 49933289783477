import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { IRouterLink } from 'core/plugins/model'
import Theme from 'core/themes/model'
import React, { MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import generateTestId from 'utils/test-helpers'
import Text, { TextVariant } from '../Text'
import Tooltip from '../tooltip'

interface Props extends Omit<IRouterLink, 'nestedLinks'> {
  isActive?: boolean
  tooltip?: string
  drawerOpen?: boolean
  compact?: boolean
  activeDisplayType?: 'background' | 'bar'
  className?: string
  tooltipProps?: { [key: string]: any }
  variant?: TextVariant
  onClick?: (event: MouseEvent) => void
  disabled?: boolean
}

export default function NavItem({
  name,
  link,
  icon,
  onClick = undefined,
  className = undefined,
  drawerOpen = false,
  isActive = false,
  compact = false,
  tooltip = '',
  tooltipProps = {},
  activeDisplayType = 'background',
  variant = 'subtitle2',
  disabled = false,
}: Props) {
  const isHeader = !!icon
  const classes = useStyles({
    isActive,
    compact,
    activeDisplayType,
    drawerOpen,
    disabled,
    isHeader,
  })

  return (
    <Link to={disabled ? undefined : link.path} onClick={onClick}>
      <li className={clsx(classes.navItem, className)}>
        <Tooltip message={tooltip ? tooltip : ''} {...tooltipProps}>
          {icon && (
            <div className={clsx(classes.navIcon)}>
              <FontAwesomeIcon
                data-testid={generateTestId(name)}
                className="nav-icon"
                title={name}
                size="lg"
              >
                {icon}
              </FontAwesomeIcon>
            </div>
          )}
          {drawerOpen && (
            <Text
              className={clsx('nav-text', classes.navText)}
              data-testid={generateTestId(name)}
              variant={compact ? 'sidenav2' : variant}
            >
              {name}
            </Text>
          )}
        </Tooltip>
      </li>
    </Link>
  )
}

interface StyleProps {
  isActive: Props['isActive']
  compact: Props['compact']
  drawerOpen: Props['drawerOpen']
  activeDisplayType: Props['activeDisplayType']
  disabled: Props['disabled']
  isHeader: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  navItem: {
    position: 'relative',
    height: ({ compact }) => (compact ? 40 : 48),
    boxSizing: 'border-box',
    display: 'grid',
    alignItems: 'center',
    '& .nav-text, & .nav-icon': {
      transition: 'color .2s ease',
    },
    '&:hover .nav-text, &:hover .nav-icon': {
      color: ({ disabled }) => (disabled ? 'inherit' : theme.components.sidebar.hoverText),
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: 0,
      width: '5px',
      height: '34px',
      transform: 'translate(0px, -50%)',
      backgroundColor: ({ isActive, isHeader }) => (isActive && !isHeader ? '#FF6968' : 'unset'),
      borderRadius: '0 4px 4px 0',
    },
    '&:after': {
      content: ({ activeDisplayType }) => (activeDisplayType === 'bar' ? '""' : ''),
      position: 'absolute',
      bottom: 0,
      top: 0,
      left: 0,
      width: 1,
      backgroundColor: theme.components.sidebar.border,
    },
  },
  navIcon: {
    minWidth: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.components.sidebar?.text,
    '& > i': {
      width: '23px',
      height: '18.5px',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.components.sidebar?.text,
    },
  },
  navText: {
    color: ({ isActive }) => theme.components.sidebar?.[isActive ? 'activeText' : 'text'],
  },
}))
