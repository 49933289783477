import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Cookies from 'js-cookie'
import { DOMAIN_NAME, loginUrl, SSO_REDIRECT_COOKIE } from 'app/constants'
import { getSSORedirectUrl, isDefaultDomain as isDefaultDomainFn } from 'core/utils/helpers'

export const parseCookies = (cookieName) => {
  const cookie = Cookies.get(cookieName)
  if (!cookie) return {}

  return cookie.split('; ').reduce((cookies, cookie) => {
    const [name, value] = cookie.split('=')
    if (name) cookies[name] = decodeURIComponent(value)
    return cookies
  }, {})
}

/*
 * Before we redirect to shibboleth, we need to set the cookie with the query params
 */
const SetCookiePage = () => {
  const [cookieSet, setCookieSet] = useState(false)
  const [error, setError] = useState(null)
  const location = useLocation()
  const urlParams: any = new URLSearchParams(location.search)
  const domainName = urlParams.get(DOMAIN_NAME)
  const isDefaultDomain = isDefaultDomainFn(domainName)

  // Handle any errors and redirect to the login page
  const handleError = (e) => {
    setError(e)
    window.location.href = loginUrl
  }

  // Set the cookie with the query params
  useEffect(() => {
    try {
      if (urlParams.size === 0) {
        handleError(new Error('No URL params found'))
        return
      }

      let cookieValue = ''
      urlParams.forEach((value, key) => {
        if (key) cookieValue = cookieValue + `${key}=${value}; `
      })

      // Set the cookie here
      Cookies.remove(SSO_REDIRECT_COOKIE)
      Cookies.set(SSO_REDIRECT_COOKIE, cookieValue, {
        expires: 1,
        secure: true,
        path: '/',
        sameSite: 'None',
      })

      // Reset the domain cookie and set the new one
      Cookies.remove(DOMAIN_NAME)
      if (domainName && !isDefaultDomain) {
        Cookies.set(DOMAIN_NAME, domainName)
      }

      setCookieSet(true)
    } catch (e) {
      handleError(e)
    }
  }, [])

  // Once the cookie is set, redirect to the SSO page
  useEffect(() => {
    if (!cookieSet) return
    window.location.href = getSSORedirectUrl(domainName)
  }, [cookieSet])

  return error ? <div>Error: {error.message}</div> : null
}

export default SetCookiePage
