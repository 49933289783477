import React from 'react'
import { NestedLink } from 'core/plugins/model'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import generateTestId from 'utils/test-helpers'
import { bottomRight } from './defaults'
import Menu, { IMenuProps } from './Menu'
import Text from '../Text'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import clsx from 'clsx'
import Divider from '../Divider'
import NavItem from '../sidebar/NavItem'
import { matchesCurrentPath } from 'core/plugins/route-helpers'
import usePluginRouter from 'core/hooks/usePluginRouter'

interface Props extends IMenuProps {
  title: string
  icon?: string
  open: boolean
  anchorEl?: JSX.Element
  nestedLinks: NestedLink[]
  onClose: () => void
}

export default function NavMenu({
  nestedLinks,
  anchorEl = null,
  open,
  onClose,
  icon,
  title,
  className,
  ...props
}: Props) {
  const classes = useStyles()
  const { currentPath } = usePluginRouter()
  return (
    <Menu
      className={clsx(classes.menuContainer, className)}
      data-testid={generateTestId('nav-section', 'menu')}
      origin="left top"
      anchor={anchorEl}
      open={open}
      align={bottomRight.align}
      onClose={onClose}
      {...props}
    >
      <div className={classes.titleContainer}>
        <Text className={clsx(classes.title, 'menu-title-text')} component="div" variant="sidenav1">
          {icon && (
            <FontAwesomeIcon size="lg" className="menu-title-icon" title={title}>
              {icon}
            </FontAwesomeIcon>
          )}
          {title}
        </Text>
        {nestedLinks?.map(({ name, link }, index) => (
          <NavItem
            key={index}
            name={name}
            link={link}
            className={classes.nestedNavItem}
            activeDisplayType="bar"
            isActive={matchesCurrentPath(currentPath, link)}
            drawerOpen={true}
            // tooltip={false}
            onClick={() => onClose()}
            compact
            variant="sidenav2"
          />
        ))}
      </div>
    </Menu>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  menuContainer: {
    '& .menu-popover': {
      padding: theme.spacing(0, 1, 2, 1),
      background: theme.components.sidebar.background,
      borderColor: theme.components.sidebar.border,
      borderRadius: theme.spacing(0.5),
    },
  },

  titleContainer: {
    padding: theme.spacing(0, 1.5),
  },
  title: {
    display: 'flex',
    padding: theme.spacing(0, 1, 0.5, 1),
    height: 44,
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.components.sidebar?.activeIcon,
    '& i': {
      fontSize: 22,
      width: 24,
      color: theme.components.sidebar.activeText,
    },
    borderBottom: `1px solid ${theme.components.sidebar.border}`,
    marginBottom: theme.spacing(1),
  },
  nestedNavItem: {
    padding: theme.spacing(0, 3),
    '&:hover': {
      borderRadius: theme.spacing(0.5),
      background: theme.components.sidebar.border,
      transition: 'background 0.2s ease',
    },

    '& .tooltip-container': {
      marginLeft: theme.spacing(2),
    },
  },
}))
