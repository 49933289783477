import { makeStyles } from '@material-ui/styles'
import React from 'react'
import withFormContext, {
  PropsWithFormContext,
} from '../../components/validatedForm/withFormContext'

import Text from 'core/elements/Text'
import ToggleSwitch, { ToggleSwitchProps } from 'core/elements/ToggleSwitch'
import Tooltip from 'core/elements/tooltip'
import Theme from 'core/themes/model'
import { compose } from 'utils/fp'
import FontAwesomeIcon from '../FontAwesomeIcon'
import { withInfoTooltip } from '../InfoTooltip'

interface BaseToggleSwitchFieldProps extends Omit<ToggleSwitchProps, 'active'> {
  id: string
  required?: boolean
  disabled?: boolean
  label?: string
  onChange?: (value?: boolean) => void
  tooltipMsg?: string
  info?: string
  customTooltipInfoBody?: React.ReactNode
}

const ToggleSwitchField = ({
  label,
  value,
  onChange,
  children,
  tooltipMsg,
  info,
  customTooltipInfoBody,
  ...restProps
}: React.PropsWithChildren<any>) => {
  const classes = useAddonFieldStyles()

  const ToggleComponent = (
    <ToggleSwitch {...restProps} active={!!value} onClick={(value) => onChange(value)} />
  )

  return (
    <div className={classes.configContainer}>
      {tooltipMsg ? (
        <Tooltip message={tooltipMsg}>{ToggleComponent}</Tooltip>
      ) : (
        <>{ToggleComponent}</>
      )}
      <div>
        <div className={classes.label}>
          <Text variant="caption1">{label}</Text>
          {info ||
            (customTooltipInfoBody && (
              <Tooltip customBody={customTooltipInfoBody} message={info}>
                <FontAwesomeIcon>circle-info</FontAwesomeIcon>
              </Tooltip>
            ))}
        </div>
      </div>
      <div className={classes.desciptionAndOptions}>{children}</div>
    </div>
  )
}

const useAddonFieldStyles = makeStyles<Theme>((theme) => ({
  configContainer: {
    display: 'grid',
    // gridTemplateColumns: 'max-content auto',
    gridTemplateColumns: 'max-content auto 1fr',
    gridGap: theme.spacing(3),
    alignItems: 'start',
  },
  desciptionAndOptions: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridGap: theme.spacing(3),
  },
  label: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: theme.spacing(1),
  },
}))

export type ToggleSwitchFieldProps = PropsWithFormContext<boolean, BaseToggleSwitchFieldProps>

export default compose(withInfoTooltip, withFormContext)(ToggleSwitchField)
