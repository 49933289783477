import React from 'react'
import withFormContext from 'core/components/validatedForm/withFormContext'
import { ValidatedFormProps } from './model'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import MultiDropdown, { MultiDropdownProps } from 'core/elements/dropdown/MultiDropdown'

interface Props extends ValidatedFormProps {
  compact?: boolean
  value?: string[]
  hasError?: boolean
  errorMessage?: string
  onChange?: any
  loading?: boolean
  options: any[]
  label?: string
  multiline?: boolean
  fullWidth?: boolean
}

export default withFormContext<any[], Props>(function(props) {
  const {
    compact,
    onChange,
    value = [],
    hasError,
    errorMessage,
    options = [],
    label,
    loading,
    ...rest
  } = props

  return (
    <>
      <MultiDropdown
        compact={compact}
        value={value}
        onChange={onChange}
        label={label}
        loading={loading}
        items={options}
        {...rest}
      />
      {hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  )
})
