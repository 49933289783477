import { IDataKeys } from 'k8s/datakeys.model'
import { ensureFunction } from 'utils/fp'
import { notificationActions, NotificationType } from 'core/notifications/notificationReducers'
import store from 'app/store'
import { memoize, generateObjMemoizer, memoizePromise } from 'utils/misc'
import { isNil } from 'ramda'
import { ActionLike } from 'core/actions/ActionLike'
import ActionOptions from 'core/actions/ActionOptions'

export type ArrayElement<
  ArrayType extends readonly unknown[]
> = ArrayType extends readonly (infer ElementType)[] ? ElementType : never

export interface ActionConfig<D extends keyof IDataKeys> {
  cacheKey: D
  addCacheKey?: keyof IDataKeys
  uniqueIdentifier: string | string[]
  indexBy?: string | string[]
  entityName?: string
  cache?: boolean
  successMessage?:
    | (<R = IDataKeys[D], P extends Record<string, unknown> = Record<string, unknown>>(
        updatedItems: R,
        prevItems: R,
        params: P,
      ) => string)
    | string
  errorMessage?:
    | (<P extends Record<string, unknown> = Record<string, unknown>>(
        err: Error,
        params: P,
      ) => string)
    | string
}

const { dispatch } = store

const actionTypeMap = {
  create: 'creating',
  delete: 'deleting',
  list: 'fetching',
  update: 'updating',
}

const errorHandler = memoize(
  (params, errorMessage, cacheKey, actionType) => ({ stack, err, ...rest }) => {
    let title = ensureFunction(errorMessage)(err, params)
    if (!title) {
      const defaultActionName = actionTypeMap[actionType]
      const actionBit = defaultActionName || `attempting to ${actionType}`
      const entityBit = defaultActionName ? ` ${cacheKey}` : ''
      title = `Error when ${actionBit}${entityBit}`
    }
    console.error(title, err)
    dispatch(
      notificationActions.registerNotification({
        title,
        message: err?.message || err?.toString(),
        data: { ...rest, stack, params },
        type: NotificationType.error,
      }),
    )
  },
)

abstract class Action<
  D extends keyof IDataKeys,
  P extends Record<string, unknown> = Record<string, unknown>,
  R = IDataKeys[D],
  O extends ActionOptions = ActionOptions
> implements ActionLike<D> {
  public abstract get name(): string

  private baseConfig: ActionConfig<D> = {
    cacheKey: null,
    addCacheKey: null,
    uniqueIdentifier: 'id',
    cache: true,
  }

  protected memoizedParams = generateObjMemoizer<P>()

  public get cacheKey(): D {
    return this.baseConfig.cacheKey
  }

  public get config(): ActionConfig<D> {
    return this.baseConfig
  }

  constructor(
    public readonly callback: (params: P) => Promise<R | void>,
    config?: Partial<ActionConfig<D>>,
    private defaultParams: P = {} as P,
  ) {
    if (config) {
      this.updateConfig(config)
    }
  }

  public updateConfig = (config: Partial<ActionConfig<D>>) => {
    this.baseConfig = Object.freeze({
      ...this.baseConfig,
      ...config,
    })
  }

  private performCallback = memoizePromise(
    (params: P): Promise<void | R> => {
      return this.callback(params)
    },
  )

  public async call(params: P = {} as P, options: Partial<O> = {}): Promise<void | R> {
    const combinedParams = this.memoizedParams({ ...this.defaultParams, ...params })
    if (isNil(this.cacheKey)) {
      throw new Error(`'cacheKey' is missing from Action configuration`)
    }
    if (this.validate(combinedParams, options)) {
      try {
        await this.preProcess(combinedParams, options)

        const result = await this.performCallback(combinedParams)

        await this.postProcess(result, combinedParams, options)

        return result
      } catch (err) {
        this.handleError(err, combinedParams, options)
      }
    }
  }

  protected validate = (params: P, options?: Partial<O>): boolean => {
    return true
  }

  protected abstract preProcess(params: P, options: Partial<O>): Promise<void> | void

  protected abstract postProcess(result: R, params: P, options: Partial<O>): Promise<void> | void

  protected handleError(err, params: P, options) {
    const { errorMessage, cacheKey } = this.baseConfig
    const { propagateError = false } = options
    errorHandler(params, errorMessage, cacheKey, this.name)(err)

    if (propagateError) {
      throw err
    }
  }
}

export default Action
