import React, { useEffect, useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTheme } from '@material-ui/styles'
import {
  activateUserUrl,
  CustomerTiers,
  forgotPasswordUrl,
  loginUrl,
  resetPasswordThroughEmailUrl,
  resetPasswordUrl,
  setCookieUrl,
} from 'app/constants'
import Progress from 'core/components/progress/Progress'
import AuthContainer from 'core/containers/AuthContainer'
import ActivateUserPage from 'core/public/ActivateUserPage'
import ForgotPasswordPage from 'core/public/ForgotPasswordPage'
import LoginPage from 'core/public/LoginPage'
import ResetPasswordPage from 'core/public/ResetPasswordPage'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { prop } from 'ramda'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router'
import useReactRouter from 'use-react-router'
import { isNilOrEmpty } from 'utils/fp'
import { appCuesSetAnonymous, trackPage } from 'utils/tracking'
import Theme from 'core/themes/model'
import {
  loadLoginFeaturesAndTracking,
  setupSession,
  validateSession,
} from 'core/containers/AppController'
import useScopedPreferences from 'core/session/useScopedPreferences'
import DocumentMeta from 'core/components/DocumentMeta'
import SetCookiePage from 'core/public/SetCookiePage'

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    html: {
      backgroundColor: theme?.components?.frame?.background,
    },
    'html body': {
      backgroundColor: theme.components?.frame?.background,
    },
    'html body #root': {
      transition: 'filter 300ms ease-out',
      filter: 'blur(0)',
      backgroundColor: theme?.components?.frame?.background,
    },
    'html body.blur #root': {
      transitionDelay: '200ms',
      filter: 'blur(1px)',
    },
    '*::-webkit-scrollbar': {
      borderRadius: 4,
      width: 14,
      height: 14,
    },

    '*.thin-scrollbar': {
      // FF supports these fields but not webkit
      scrollbarColor: `${theme?.components?.scrollbar?.thumb} ${theme?.components?.card?.background}`,
      scrollbarWidth: 'thin',
    },
    '*.thin-scrollbar::-webkit-scrollbar': {
      borderRadius: 4,
      width: 6,
      height: 6,
    },

    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': `inset 0 0 200px ${theme?.components?.scrollbar?.track}`,
      borderRadius: 4,
    },

    '*::-webkit-scrollbar-thumb': {
      '-webkit-box-shadow': `inset 0 0 200px ${theme?.components?.scrollbar?.thumb}`,
      borderRadius: 14,

      // make the thumb look thinner than the track
      border: `3px solid ${theme?.components?.scrollbar?.track}`,
    },

    '*.thin-scrollbar::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      border: `1px solid ${theme?.components?.scrollbar?.track}`,
    },
  },
  root: {
    flexGrow: 1,
  },
  progress: {
    '& img': {
      height: 130,
    },
    '& img ~ span': {
      fontSize: theme.typography.subtitle1.fontSize,
    },
  },
}))

/**
 * Sets up the Openstack session.
 * Renders children when logged in.
 * Otherwise shows the <LoginPage>
 */
const AppContainer = () => {
  const classes = useStyles()
  const theme: Theme = useTheme()
  const { history } = useReactRouter()
  const [loginFeatures, setLoginFeatures] = useState<{
    customerTier: CustomerTiers
    ssoEnabled: boolean
  }>(null)
  const [sessionChecked, setSessionChecked] = useState(false)
  const { getUserPrefs } = useScopedPreferences()
  const session = useSelector(prop<string, SessionState>(sessionStoreKey))
  const { features } = session

  const frameColor = theme?.components?.frame?.background

  const metaThemeProps = useMemo(
    () => [
      {
        name: 'theme-color',
        content: frameColor,
      },

      {
        name: 'msapplication-navbutton-color',
        content: frameColor,
      },

      {
        name: 'apple-mobile-web-app-capable',
        content: 'yes',
      },
      {
        name: 'apple-mobile-web-app-status-bar-style',
        content: 'black-translucent',
      },
    ],
    [frameColor],
  )

  useEffect(() => {
    const { pathname, hash } = history.location

    const unlisten = history.listen((location) => {
      trackPage(`${location.pathname}${location.hash}`)
      if (features?.experimental?.sandbox) {
        appCuesSetAnonymous()
      }
    })

    // This is to send page event for the first page the user lands on
    trackPage(`${pathname}${hash}`)
    if (features?.experimental?.sandbox) {
      appCuesSetAnonymous()
    }

    const initialize = async () => {
      setLoginFeatures(await loadLoginFeaturesAndTracking())
      await validateSession(getUserPrefs, session, history)
      setSessionChecked(true)
    }
    initialize()

    // TODO: Need to fix this code after synching up with backend.
    if (hash.includes(resetPasswordThroughEmailUrl)) {
      history.replace(hash.slice().replace('#', '/ui'))
    }

    return unlisten
  }, [])

  // return <Progress loading message={'Loading app'} />

  const authContent =
    isNilOrEmpty(session) || !session.username ? <Redirect to={loginUrl} /> : <AuthContainer />

  // Do not let the rest of the UI load until we have a working session.
  return (
    <div className={classes.root} id="_main-container">
      <DocumentMeta meta={metaThemeProps} />
      <Switch>
        <Route path={setCookieUrl} component={SetCookiePage} />
        <Route path={resetPasswordUrl} component={ResetPasswordPage} />
        <Route path={forgotPasswordUrl} component={ForgotPasswordPage} />
        <Route path={activateUserUrl} component={ActivateUserPage} />
        <Route path={loginUrl}>
          {loginFeatures && (
            <LoginPage
              onAuthSuccess={setupSession}
              ssoEnabled={loginFeatures.ssoEnabled}
              customerTier={loginFeatures.customerTier}
            />
          )}
        </Route>
        <Route>
          {sessionChecked ? (
            authContent
          ) : (
            <div className={classes.progress}>
              <Progress loading message={'Loading app'} />
            </div>
          )}
        </Route>
      </Switch>
    </div>
  )
}

export default AppContainer
