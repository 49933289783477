import React from 'react'
import { withStyles } from '@material-ui/styles'
import { withRouter } from 'react-router'
import { logoutUrl, helpUrl, CustomerTiers } from 'app/constants'
import { connect } from 'react-redux'
import { sessionStoreKey } from 'core/session/sessionReducers'
import RegionMenu from './RegionMenu'
import TenantMenu from './tenant-menu'
import Text from 'core/elements/Text'
import Menu from 'core/elements/menu/Menu'
import MenuItem from 'core/elements/menu/MenuItem'
import Avatar from './Avatar'
import { routes } from 'core/utils/routes'
import { isAdminRole } from 'k8s/util/helpers'
import { pathOr } from 'ramda'
import Theme from 'core/themes/model'
import { showAndOpenZendeskWidget } from 'utils/zendesk-widget'
import { showZendeskChatOption } from 'core/utils/helpers'
import { preferencesStoreKey } from 'core/session/preferencesReducers'
import generateTestId from 'utils/test-helpers'
import { switchCase } from 'utils/fp'

const styles = (theme: Theme) => ({
  userDetails: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    alignItems: 'center',
    paddingLeft: 8,
    gap: 16,
  },
  dropdownContainer: {
    padding: 16,
    display: 'grid',
    gridTemplateRows: 'max-content 1fr max-content',
    gap: 16,
  },
  dropdownLinks: {
    display: 'grid',
    gridAutoRows: 48,
  },
  dropdownSubMenus: {
    position: 'relative',
    display: 'grid',
    gridAutoRows: 48,
    '&:after': {
      content: '""',
      position: 'absolute',
      top: -8,
      left: 0,
      right: 0,
      height: 1,
      backgroundColor: theme.components.card.border,
    },
  },
  enterpriseLabel: {
    color: theme.palette.pink.main,
  },
  growthLabel: {
    color: theme.palette.yellow.main,
  },
  freedomLabel: {
    color: theme.palette.blue.main,
  },
})
const menuOffset = {
  vertical: 8,
  horizontal: -38, // diameter is 36 with 1px boder on each side
}

// @ts-ignore
@withStyles(styles)
@withRouter
// @ts-ignore
@connect((store) => ({ session: store[sessionStoreKey], preferences: store[preferencesStoreKey] }))
class UserMenu extends React.PureComponent<any> {
  state = { anchorEl: null, showChangePasswordModal: false }
  handleClick = (event) => this.setState({ anchorEl: event.currentTarget })
  handleClose = () => this.setState({ anchorEl: null })
  logout = () => this.props.history.push(logoutUrl)

  render() {
    const { classes, className, session, preferences } = this.props
    const { anchorEl } = this.state
    const {
      username,
      userDetails: { displayName },
      features = {},
    } = session

    return (
      <div className={className}>
        <Menu
          id="user-menu"
          origin="top right"
          offset={menuOffset}
          anchor={
            <Avatar
              onClick={this.handleClick}
              displayName={displayName}
              diameter={36}
              fontSize={16}
            />
          }
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <div className={classes.dropdownContainer}>
            <div className={classes.userDetails}>
              <Avatar displayName={displayName} diameter={48} fontSize={18} />
              <div>
                <Text variant="subtitle2">{displayName}</Text>
                <Text variant="inputTable" component="p">
                  {username}
                </Text>
              </div>
            </div>
            <div className={classes.dropdownLinks}>
              {/* <MenuItem
                src={routes.userSettings.root.path()}
                onClick={this.handleClose}
                icon="user"
                data-testid={generateTestId('my', 'account')}
                component="p"
              >
                My Account
              </MenuItem> */}
              {isAdminRole(session) && (
                <MenuItem
                  src={routes.userSettings.root.path()}
                  onClick={this.handleClose}
                  data-testid={generateTestId('admin', 'settings')}
                  icon="cog"
                  component="p"
                >
                  Admin Settings
                </MenuItem>
              )}
              {/* <MenuItem
                src={helpUrl}
                onClick={this.handleClose}
                icon="question-circle"
                component="p"
              >
                Support
              </MenuItem> */}
              <MenuItem icon="sign-out" onClick={this.logout} component="p">
                Sign Out
              </MenuItem>
            </div>
            {/* <div className={classes.dropdownSubMenus}>
              <RegionMenu />
              <TenantMenu />
            </div> */}
          </div>
        </Menu>
      </div>
    )
  }
}

export default UserMenu
