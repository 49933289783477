import ApiClient from 'api-client/ApiClient'
import {
  appPlugins,
  AppPlugins,
  CustomerTiers,
  GlobalFeatureFlags,
  GlobalPreferences,
} from 'app/constants'
import store from 'app/store'
import { preferencesActions } from 'core/session/preferencesReducers'
import { sessionActions } from 'core/session/sessionReducers'
import { isDecco } from 'core/utils/helpers'
import { Route as Router } from 'core/plugins/route'
import { pathOr } from 'ramda'
import { memoize } from 'utils/misc'

const { preferenceStore, keystone } = ApiClient.getInstance()
const { dispatch } = store

export const updateGlobalFeatureFlags = async (newFlags = {}, globalFeatureFlags) => {
  try {
    await preferenceStore.updateGlobalPreference(GlobalPreferences.FeatureFlags, {
      ...globalFeatureFlags,
      ...newFlags,
    })
    dispatch(
      preferencesActions.updateGlobalPrefs({
        [GlobalPreferences.FeatureFlags]: newFlags,
      }),
    )
  } catch (err) {
    console.error(err)
  }
}

export const updateOnboardingStatus = async () => {
  try {
    const response: any = await preferenceStore.getGlobalPreference(GlobalPreferences.FeatureFlags)
    if (!response) return
    const featureFlags: GlobalFeatureFlags = JSON.parse(response.value)
    dispatch(
      preferencesActions.updateGlobalPrefs({
        [GlobalPreferences.FeatureFlags]: featureFlags,
      }),
    )
  } catch (err) {
    // A 404 error occurs when you try to do a GET request for a key that does not exist
    // in the pref store.
    dispatch(
      preferencesActions.updateGlobalPrefs({
        [GlobalPreferences.FeatureFlags]: {
          isOnboarded: false,
        },
      }),
    )
  }
}

export const determineCurrentStack = memoize((location, lastStack) => {
  const currentRoute = Router.getCurrentRoute()
  const handleReturn = () => {
    if (lastStack) {
      return lastStack
    }
    return AppPlugins.OpenStack
  }

  if (!currentRoute) return handleReturn()

  const match = currentRoute.pattern.match(location.pathname)
  if (!match) return handleReturn()

  if (appPlugins.includes(match.plugin)) {
    return match.plugin
  }
  return handleReturn()
})

export const loadRegionFeatures = async () => {
  try {
    const features = await keystone.getFeatures()

    // Store entirety of features json in context for global usage
    dispatch(sessionActions.updateSession({ features }))
  } catch (err) {
    console.error(err)
  }
}

export const getSandboxUrl = (pathPart) => `https://platform9.com/${pathPart}/`

export function isOnboardingEnv(currentStack, features) {
  const customerTier = pathOr<CustomerTiers>(CustomerTiers.Freedom, ['customer_tier'], features)
  return (
    currentStack === AppPlugins.Kubernetes &&
    isDecco(features) &&
    customerTier === CustomerTiers.Freedom
  )
}

export function needsOnboardingBackfill(
  currentStack,
  features,
  isOnboarded,
  clusters,
  clustersUpdating,
  users,
) {
  const isOnboardingTargetEnv = isOnboardingEnv(currentStack, features)
  return (
    isOnboardingTargetEnv &&
    isOnboarded === false &&
    !clustersUpdating &&
    clusters?.length > 0 &&
    users?.length > 1
  )
}
