import React from 'react'
import withFormContext from 'core/components/validatedForm/withFormContext'
import ListTable from 'core/components/listTable/ListTable'
import { ValidatedFormProps } from './model'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'

interface Props extends ValidatedFormProps {
  value?: string[]
  hasError?: boolean
  errorMessage?: string
  onChange?: any
  multiSelection?: boolean
  columns?: any
  data: any
  title?: string
  extraToolbarContent?: JSX.Element
  checkboxCond?: any
  loading: boolean
  uniqueIdentifier?: string
  onReload?: any
  searchTargets?: string[]
  orderBy?: string
  dataTestId?: string
  emptyText?: string
  noScroll?: boolean
  showCheckboxes?: boolean
}

export default withFormContext<any[], Props>(function(props) {
  const {
    onChange,
    value = [],
    hasError,
    errorMessage,
    multiSelection = false,
    columns,
    data,
    title,
    extraToolbarContent,
    checkboxCond,
    loading,
    uniqueIdentifier,
    onReload,
    searchTargets = ['name'],
    orderBy,
    dataTestId,
    emptyText,
    noScroll,
    showCheckboxes = true,
  } = props

  return (
    <>
      <ListTable
        onSelectedRowsChange={onChange}
        title={title}
        columns={columns}
        data={data}
        multiSelection={multiSelection}
        selectedRows={value}
        extraToolbarContent={extraToolbarContent}
        checkboxCond={checkboxCond}
        loading={loading}
        uniqueIdentifier={uniqueIdentifier}
        onReload={onReload}
        searchTargets={searchTargets}
        orderBy={orderBy}
        dataTestId={dataTestId}
        emptyText={emptyText}
        noScroll={noScroll}
        showCheckboxes={showCheckboxes}
      />
      {hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  )
})
