import React from 'react'
import { useSelector } from 'react-redux'
import useReactRouter from 'use-react-router'
import { prop } from 'ramda'
import { RootState } from 'app/store'
import { ClientState, clientStoreKey } from 'core/client/clientReducers'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import usePluginRouter from 'core/hooks/usePluginRouter'
import { Route as Router } from 'core/plugins/route'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { isAdmin } from 'app/plugins/infrastructure/components/common/helpers'

import Crumb from './Crumb'
import { getCrumbs } from './helpers'

const processCrumbName = (crumb) => {
  if (!isAdmin()) {
    return crumb?.name === 'Physical Networks' ? 'Networks' : crumb?.name
  }
  return crumb?.name
}

export default function Breadcrumbs() {
  const { currentLink } = usePluginRouter()
  const { match } = useReactRouter()
  const { breadcrumbParams } = useSelector<RootState, ClientState>(prop(clientStoreKey))
  const classes = useStyles({})
  const currentRoute = Router.getCurrentRoute(null, true)

  if (!currentRoute) {
    console.error('[breadcrumbs] no current route found for path', location.pathname)
    return null
  }

  const crumbs = getCrumbs(currentRoute.breadcrumbs, breadcrumbParams, match.params)

  return (
    <ul
      className={clsx(classes.breadcrumbs, classes.gridContainer, {
        [classes.fourCrumbs]: crumbs.length === 4,
        [classes.fiveCrumbs]: crumbs.length === 5,
      })}
    >
      {crumbs.map((crumb, idx) => {
        const name = processCrumbName(crumb)
        return (
          <Crumb
            key={name}
            leftIcon={
              idx === 0 && (
                <FontAwesomeIcon className={clsx(classes.icon, classes.primaryIcon)}>
                  {currentLink?.icon}
                </FontAwesomeIcon>
              )
            }
            name={name}
            path={crumb.path}
            icon="chevron-right"
            active={idx === crumbs.length - 1}
          />
        )
      })}
    </ul>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  gridContainer: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'start',
    gridAutoFlow: 'column',
    gap: 8,
  },
  breadcrumbs: {
    gap: 8,
    padding: 0,
    margin: 0,
  },
  primaryIcon: {
    fontSize: 20,
    color: theme.components.breadcrumb.text,
  },
}))
