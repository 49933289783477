import React, { forwardRef, useCallback, useState } from 'react'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { useDropzone } from 'react-dropzone'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import withFormContext from 'core/components/validatedForm/withFormContext'
import SimpleLink from 'core/components/SimpleLink'

interface Props {
  onChange: (value: any) => void
  fileTypes: string[]
  value?: string
  id?: string
  validations?: any[]
  hasError?: boolean
  errorMessage?: string
  maxFiles?: number
}

const useStyles = makeStyles((theme: Theme) => ({
  dropzone: {
    padding: '16px 16px 32px',
    background: theme.components.card.background,
    border: `1px dashed ${theme.components.dropdown.border}`,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    position: 'relative',
  },
  uploadIcon: {
    color: theme.components.card.passiveText,
  },
  iconContainer: {
    background: theme.components.card.activeBackground,
    width: 40,
    height: 40,
    borderRadius: 20,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 25,
  },
  dropzoneText: {
    marginTop: 8,
  },
  imagePreview: {
    maxHeight: 44,
    maxWidth: 48,
  },
  trash: {
    position: 'absolute',
    top: 12,
    right: 12,
    color: theme.components.card.passiveText,
    cursor: 'pointer',
  },
  errorText: {
    color: theme.components.graph.error,
  },
  loadedFile: {
    marginTop: 8,
  },
}))

const Warning = ({ children }) => {
  const classes = useStyles({})
  return (
    <Text variant="body1" className={classes.errorText}>
      {children}
    </Text>
  )
}

const TextFileDrop: React.ComponentType<Props> = forwardRef<HTMLElement, Props>(
  (props, ref: React.Ref<HTMLDivElement>) => {
    const [fileNames, setFileNames] = useState([])
    const { onChange, fileTypes = [], maxFiles = 1, value, hasError, errorMessage } = props

    const classes = useStyles({})

    const onDrop = useCallback(async (acceptedFiles) => {
      if (maxFiles === 1) {
        const file = acceptedFiles[0]

        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
          setFileNames([file?.name])
          const text = String(reader.result)
          onChange(text)
        }
        reader.readAsText(file)
      } else {
        const fileData = {}
        const readFile = (file) => {
          return new Promise((resolve) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
              // setFileName(file?.name)
              fileData[file.name] = String(reader.result)
              // const text = String(reader.result)
              // onChange(text)
            }
            reader.onloadend = () => {
              resolve(reader.result)
            }
            reader.readAsText(file)
          })
        }
        const results = await Promise.allSettled(acceptedFiles.map(readFile))
        onChange(fileData)
        const fileNames = Object.keys(fileData)
        setFileNames(fileNames)
      }
    }, [])
    const { getRootProps, getInputProps, open } = useDropzone({
      onDrop,
      noClick: true,
      accept: fileTypes.join(', '),
      maxFiles,
    })

    return (
      <div>
        <div className={classes.dropzone} {...getRootProps()}>
          <input {...getInputProps()} />
          <div>
            <div className={classes.iconContainer}>
              <img src="/ui/images/cloud-upload@3x.png" className={classes.icon} />
            </div>
            {!!fileNames.length &&
              fileNames.map((fileName) => (
                <div key={fileName} className={classes.loadedFile}>
                  <Text variant="caption1">
                    <FontAwesomeIcon>check</FontAwesomeIcon> {fileName}
                  </Text>
                </div>
              ))}
            <div className={classes.dropzoneText}>
              <Text variant="body2">
                <SimpleLink onClick={open}>Click to upload</SimpleLink> <b>or drag and drop</b>
              </Text>
              <Text variant="body2">Only YAML scripts supported</Text>
            </div>
          </div>
        </div>
        {hasError && <Warning>{errorMessage}</Warning>}
      </div>
    )
  },
)

export default withFormContext(TextFileDrop) as React.FC<Props>
